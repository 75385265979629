import { createContext, useState } from "react";
import Notification from "../components/notification/Notification";

export const NotificationContext = createContext();

export default ({ children }) => {
  const [showNotif, setShowNotif] = useState(false);
  const [notification, setNotification] = useState({
    titre: "",
    type: "primary",
    autohide: true,
  });
  return (
    <NotificationContext.Provider value={{ setShowNotif, setNotification }}>
      <Notification
        setShowToast={setShowNotif}
        showToast={showNotif}
        autohide={
          notification.autohide !== undefined ? notification.autohide : true
        }
        titre={notification.titre}
        variant={notification.type}
      />
      {children}
    </NotificationContext.Provider>
  );
};
