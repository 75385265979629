import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import NotificationContext from "./context/NotificationContext";
import AuthContext from "./context/AuthContext";
import { CookiesProvider } from "react-cookie";

ReactDOM.render(
  <CookiesProvider>
    <AuthContext>
      <NotificationContext>
        <App />
      </NotificationContext>
    </AuthContext>
  </CookiesProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
