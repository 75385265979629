import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useContext } from "react";
// TODO: To change to marketplace logo and all information

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import { faChevronUp } from "@fortawesome/pro-light-svg-icons";
import AssistanceRessources from "./AssistanceRessources";

import { AuthContext } from "../../context/AuthContext";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import data from "../../data/typeArtiste.json";

import ConditionsGenerales from "../../pages/footer/CG/ConditionsGenerales";
import Confidentialité from "../../pages/footer/confidentialite/Confidentialite";
import MentionLegale from "../../pages/footer/mentions_legales/MentionLegale";

export const Footer = () => {
  const [showConditionsGenerales, setshowConditionsGenerales] = useState(false);
  const [showMentionLegale, setShowMentionLegale] = useState(false);
  const [showPolitiqueConfidentialite, setShowPolitiqueConfidentialite] =
    useState(false);
  const [showAssistance, setShowAssistance] = useState(false);

  const navigate = useNavigate();

  const { currentDBUser, currentUser } = useContext(AuthContext);

  return (
    <Container
      className="position_center white px-2 w-100 pt-5 container-black"
      style={{
        flexDirection: "column",
        maxWidth: "none",
        overflow: "hidden",
      }}
      id="footer"
    >
      <Row
        className="position_center my-2 px-5 mx-5 w-100"
        style={{ maxWidth: "1000px" }}
      >
        {!!!currentUser.accessToken && (
          <>
            {/* <h2>Contactez-nous si vous avez des questions</h2> */}
            <Col md={3} className="light-grey">
              <Link to="/register/artiste" className="nav-link white">
                Je suis un artiste
              </Link>
            </Col>
            <Col md={3} className="light-grey">
              <Link to="/register/organisateur" className="nav-link white">
                Je suis un organisateur
              </Link>
            </Col>
          </>
        )}
        {/* <Col md={3} className='light-grey'>
          Besoin d'aide ?
        </Col> */}
        <Col md={3} className="light-grey">
          {!!currentUser.accessToken ? (
            <Link to={`/profile/${currentUser.uid}`} className="nav-link white">
              Mon compte
            </Link>
          ) : (
            <Link to="/login" className="nav-link white">
              Se connecter
            </Link>
          )}
        </Col>
        <hr className="light-grey my-3" />
      </Row>
      {((!!currentDBUser && currentDBUser.accountType !== "artiste") ||
        currentDBUser === null) && (
        <Row className="d-flex justify-content-center align-items-start my-2 px-5">
          <p className="yellow"> Artiste par type d'évènement</p>

          {[...Array(Math.ceil(data.typeEvent.length / 5)).fill(1)].map(
            (value, index) => (
              <Col
                key={"event_name_content_" + value + index}
                md={Math.ceil(12 / Math.ceil(data.typeEvent.length / 5))}
                xs={12}
                className="py-2 px-0 mx-0"
              >
                {data.typeEvent
                  .slice(index * 5, index * 5 + 5)
                  .map((eventName, key) => (
                    <Button
                      key={"event_name_" + key}
                      variant="empty"
                      className="btn-link w-80 white p-0"
                      onClick={() =>
                        navigate("/collections/artists", {
                          state: {
                            typeEventParam: eventName.replaceAll("/", "-"),
                          },
                        })
                      }
                    >
                      {eventName}
                    </Button>
                  ))}
              </Col>
            )
          )}
        </Row>
      )}
      <Row className="position_center my-2 w-80">
        <Col md={2} xs={4}>
          <a
            href={"https://www.linkedin.com/company/booknowartiste/"}
            target="_blank"
            rel="noopener noreferrer"
            className="grey-light"
          >
            <FontAwesomeIcon
              icon={faLinkedinIn}
              className="fa-2x icon-hover w-auto my-3 "
            />
          </a>
        </Col>
        <Col md={2} xs={4}>
          <a
            href="https://www.instagram.com/booknowfr/"
            target="_blank"
            rel="noopener noreferrer"
            className="grey-light"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              className="fa-2x icon-hover w-auto my-3 "
            />
          </a>
        </Col>

        <Col md={2} xs={4}>
          <a
            href="https://www.youtube.com/@BookNowFR"
            target="_blank"
            rel="noopener noreferrer"
            className="grey-light"
          >
            <FontAwesomeIcon
              icon={faYoutube}
              className="fa-2x icon-hover w-auto mx-3 "
            />
          </a>
        </Col>
        <Col md={2} xs={4}>
          <a
            href="https://www.facebook.com/booknowfr"
            target="_blank"
            rel="noopener noreferrer"
            className="grey-light"
          >
            <FontAwesomeIcon
              icon={faFacebookF}
              className="fa-2x icon-hover w-auto mx-3 "
            />
          </a>
        </Col>
      </Row>

      <Row className="w-100  mt-2 mb-2">
        <Col className="dark-grey my-auto" md={3} xs={12}>
          @Copyright 2024
        </Col>

        <Col md={6} xs={12} className="my-auto">
          <Button
            variant="underline-check"
            className="btn-empty dark-grey mx-3"
            onClick={() => setShowMentionLegale(true)}
          >
            Mentions générales
          </Button>
          |
          <Button
            variant="underline-check"
            className="btn-empty dark-grey mx-3 my-2"
            onClick={() => setshowConditionsGenerales(true)}
          >
            Conditions générales
          </Button>
          |
          <Button
            variant="underline-check"
            className="btn-empty dark-grey mx-3"
            onClick={() => setShowPolitiqueConfidentialite(true)}
          >
            Politique de confidentialité
          </Button>
          <ConditionsGenerales
            show={showConditionsGenerales}
            setShow={setshowConditionsGenerales}
          />
          <Confidentialité
            show={showPolitiqueConfidentialite}
            setShow={setShowPolitiqueConfidentialite}
          />
          <MentionLegale
            show={showMentionLegale}
            setShow={setShowMentionLegale}
          />
        </Col>
        <Col className=" dark-grey my-auto" md={3} xs={12}>
          {(!!currentDBUser && currentDBUser.accountType !== "artiste") ||
          currentDBUser === null ? (
            <Button
              variant="underline-check"
              className="btn-empty dark-grey mx-3"
              onClick={() => setShowAssistance(!showAssistance)}
            >
              Assistances et ressources
              <FontAwesomeIcon icon={faChevronUp} className="mx-3" />
            </Button>
          ) : (
            <Button
              variant="underline-check"
              className="btn-empty dark-grey mx-3"
              onClick={() => navigate("/collections/events")}
            >
              Catalogue des évènements
            </Button>
          )}
        </Col>
      </Row>
      <Fade bottom duration={4000}>
        <AssistanceRessources
          showAssistance={showAssistance}
          setShowAssistance={setShowAssistance}
        />
      </Fade>
    </Container>
  );
};
