import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

// Component
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserMusic } from "@fortawesome/pro-light-svg-icons";

import { AuthContext } from "../../context/AuthContext";
import data from "../../data/typeArtiste.json";

export const LandingArtiste = () => {
  const { currentUser } = useContext(AuthContext);
  const typeArtiste1 = Object.keys(data.typeArtist);
  const navigate = useNavigate();

  const [what, setWhat] = useState("");
  return (
    <>
      <Container
        className="px-5 position_center"
        style={{ height: "90vh", flexDirection: "column" }}
      >
        <Row className="position_center px-5">
          <h1 className=" grow fade-in title black mb-5">
            Devenez prestataire
          </h1>
          <p className="light px-5">
            Chaque jour, des clients se rendent sur Book'Now pour trouver et
            réserver des artistes de qualité pour leur
            <br /> événement privé ou professionnel. Vous augmentez votre
            chiffre d'affaires tout en restant maître de votre activité.
          </p>
        </Row>

        <Row className="w-100 mt-5 position_center">
          <Form className="w-100 mb-4 position_center flex-column">
            <Form.Label className="align-self-start">Vous êtes ?</Form.Label>
            <Row className="w-100 position_center">
              <InputGroup.Text style={{ width: "0.25rem " }}>
                <FontAwesomeIcon icon={faUserMusic} />
              </InputGroup.Text>

              <Form.Select
                value={what}
                onChange={(e) => setWhat(e.target.value)}
                aria-label="Default select example"
                style={{ width: "85%", paddingLeft: "50px" }}
              >
                <option value="">Tous types d'artistes</option>
                {typeArtiste1.map((type) => (
                  <option value={type} key={type}>
                    {type}
                  </option>
                ))}
              </Form.Select>
            </Row>
          </Form>
        </Row>
        <Row className="mt-5">
          <Button
            size="md"
            variant="primary"
            className="position_center"
            onClick={() => {
              if (currentUser) {
                navigate("/collections/events", {
                  state: { what },
                });
              } else {
                navigate("/register/artiste");
              }
            }}
          >
            {currentUser ? "Trouver un événement" : "S'inscrire"}
          </Button>
        </Row>
      </Container>
      {/* Section de proposition d'évenement */}
      {/* <Container className='my-5'>
        <Row>
          <p className='align-self-start w-auto'>
            Voici les prestations qui peuvent vous intéresser :
          </p>
        </Row>
        <Row>
          <Col className='my-2'>
            <CardEvent />
          </Col>
          <Col className='my-2'>
            <CardEvent />
          </Col>
          <Col className='my-2'>
            <CardEvent />
          </Col>
        </Row>
      </Container> */}

      <section className="background-white">
        <Container className="my-5">
          {/* <Fade bottom> */}
          <Row>
            <h2>Envoyez un devis à vos clients externes</h2>
            {/* <h4>
              Contactez-nous et un conseiller vous répondra le plus rapidement
              possible
            </h4> */}
            <Link
              to={`/profile/${currentUser.uid}`}
              className="position_center"
            >
              <Button
                variant="primary"
                className=" position_center my-4"
                size="md"
              >
                Générer un devis
              </Button>
            </Link>
          </Row>
          {/* </Fade> */}
        </Container>
      </section>
    </>
  );
};
