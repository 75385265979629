import React, { useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import "./adress-select.scss";
export default ({
  className,
  style,
  placeholder,
  onBlur = (e) => {
    console.log("onBlur");
  },
  isCity = false,
}) => {
  const [value, setValue] = useState(null);
  return (
    <div
      className={className + " adress-select"}
      style={{ width: "100%", ...style }}
    >
      <GooglePlacesAutocomplete
        apiKey={import.meta.env.VITE_GOOGLE_API_KEY}
        selectProps={{
          value,
          onChange: (e) => {
            setValue(e);
            e?.label && onBlur(e.label);
          },
          placeholder: placeholder,
        }}
        apiOptions={{ language: "fr", region: "fr" }}
        autocompletionRequest={
          isCity
            ? {
                types: ["(cities)"],
                componentRestrictions: {
                  country: ["fr"],
                },
              }
            : {
                componentRestrictions: {
                  country: ["fr"],
                },
              }
        }
      />
    </div>
  );
};
