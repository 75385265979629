import React from "react";

import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// TODO
const MentionLegale = ({ show, setShow }) => {
  return (
    <Modal show={show} onHide={() => setShow(false)} size="lg">
      <Modal.Header closeButton>
        <h3>Mentions légales</h3>
      </Modal.Header>
      <Modal.Body>
        <Container className="light">
          <Row className="my-3">
            <h5 className="underline">Éditeur du site : </h5>
            <p>
              BOOK’NOW, société par actions simplifiée, immatriculée auprès du
              RCS de Paris sous le numéro 920006616, domiciliée à Paris (75008)
              60 rue François 1er, au capital social de 16 000 euros, ayant pour
              Président, M. Wilfried SIAR.
              <br />
              Contact : contact@book-now.fr
              <br />
              TVA intracommunautaire : FR96920006616
              <br />
            </p>
          </Row>

          <Row className="my-3">
            <h5 className="underline">Hébergeur du site :</h5>
            <p>
              Société FIREBASE HOSTING par GOOGLE,
              <br />
              dont le siège social est situé au Google LLC,
              <br />
              1600 Amphitheatre Parkway,
              <br />
              Mountain View,
              <br />
              California 94043 USA.
            </p>
          </Row>
          <Row className="my-3">
            <h5 className="underline">Développeurs du site :</h5>
            <p>
              1. Cette application web a été réalisé par Kévin SANANIKONE et
              Jiawei YANG
              <br />
              Vous pouvez nous contacter par mail à<br />
              sananikone.kevin@gmail.com ou sur
              <a
                href="https://www.kevinsananikone.com"
                target="_blank"
                className="hover-primary"
              >
                {" "}
                kevinsananikone.com
              </a>
            </p>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default MentionLegale;
