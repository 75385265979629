import React from "react";

import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

export const PolitiqueContent = () => (
  <Container className="light text-start px-5">
    <Row className="my-3">
      Le site internet www.book-now.fr (ci-après « le Site internet ») est édité
      et exploité par la société BOOK’NOW (ci-après « BOOK’NOW ») ayant son
      siège social à Paris (75008) 60 rue François 1er.. BOOK’NOW est une
      plateforme de mise en relation entre des organisateurs d’évènements
      (ci-après « Organisateurs » et des artistes (ci-après « Artistes »). Dans
      le cadre de son activité, BOOK’NOW collecte et traite des données à
      caractère personnel des utilisateurs pouvant être de simples utilisateurs,
      des Artistes et/ou des Organisateurs (ci-après « Utilisateurs »). La
      présente Politique de Confidentialité a pour objet d’informer les
      Utilisateurs des moyens mis en œuvre pour collecter, traiter et conserver
      leurs données personnelles. BOOK’NOW en qualité de responsable de
      traitement, s’engage à respecter les dispositions du règlement (UE)
      n°2016/679 du 27 avril 2016 relatif à la protection des données à
      caractère personnel (ci-après « RGPD »). BOOK’NOW doit collecter certaines
      données afin de pouvoir réaliser ses prestations. Si l’Utilisateur ne
      souhaite pas communiquer ces données, BOOK’NOW ne pourra pas accomplir ses
      prestations. La présente Politique de Confidentialité peut être modifiée à
      tout moment par BOOK’NOW, notamment afin de se conformer aux évolutions
      règlementaires, jurisprudentielles ou techniques. L’utilisateur doit se
      référer avant toute navigation à la dernière version de la présente
      Politique de Confidentialité sur le Site Internet.
    </Row>
    <Row className="my-3">
      <h5 className="bold">1. La collecte des données personnelles </h5>
      Les données personnelles susceptibles d’être collectées par BOOK’NOW sont
      les suivantes.
      <br />
      <br />
      1.1 Les données que l’Utilisateur renseigne lors de la création d’un
      compte en remplissant le formulaire d’inscription. <br />
      <br />
      - Pour l’Organisateur : son nom, prénom, email, numéro de téléphone,
      statut légal, une photographie, ses coordonnées bancaires, <br />
      <br />- Pour l’Artiste selon qu’il soit intermittent, personne morale ou
      physique : son nom, prénom, email, numéro de téléphone, statut légal, une
      photographie, un numéro de sécurité social, date de naissance, commune et
      département de naissance, ses coordonnées bancaires, des informations
      d’ordre fiscal, une copie de sa pièce d’identité en cours de validité, une
      attestation d’un employeur, un extrait KBIS ou SIREN de sa structure, les
      statuts de la personne morale, <br />
      <br />- Pour tout organisateur externe : son nom, prénom, email, numéro de
      téléphone, ses coordonnées bancaires. <br />
      <br />
      1.2 Les données que l’Utilisateur renseigne lorsqu’il fait passer un ordre
      de commande en ligne (les renseignements relatifs à ses moyens de
      paiement, son numéro de TVA intracommunautaire …). <br />
      <br />
      1.3 Les données relatives à la navigation de l’Utilisateur (notamment la
      date, l’heure de connexion ou de navigation, le type de navigateur, la
      langue du navigateur, l’adresse IP et les données de géolocalisation).{" "}
    </Row>
    <Row className="my-3">
      <h5 className="bold">2. La finalité des données récoltées</h5> Les données
      récoltées par BOOK’NOW ont pour finalité :<br />
      <br />
      - Le suivi et le traitement des ordres de mission sollicités et réalisés
      par les Organisateurs, organisateur externes ou les Artistes, <br />
      <br />- L’exécution de ses services tels que définis dans ses conditions
      générales,
      <br />
      <br /> - La création et la gestion d’un compte client, <br />
      <br />- Le transfert des données vers un prestataire bancaire externe,
      pour la réalisation des transactions et validation du paiement, <br />
      <br />- L’envoi d’une newsletter ainsi que des communications marketing
      destinées à promouvoir les services de BOOK’NOW à condition que
      l’Utilisateur l’accepte expressément, <br />
      <br />- Le respect des obligations légales et règlementaires, <br />
      <br />- L’évaluation et l’amélioration des services fournis et de
      l’expérience utilisateur. BOOK’NOW ne partage aucune donnée à des fins
      commerciales à des tiers. Certaines données des Utilisateurs peuvent être
      transmises aux partenaires de BOOK’NOW lorsque leur intervention est
      nécessaire dans le traitement et la gestion des commandes. Les
      destinataires de ces données sont notamment les prestataires bancaires,
      toute autorité de police ou administrative compétente. Les réseaux sociaux
      et/ou les plateformes de streaming sur lesquels BOOK’NOW publie du contenu
      éditorial et promotionnel peuvent aussi collecter des données des
      Utilisateurs. Le traitement de ces données est soumis à leur politique
      interne et BOOK’NOW ne pourra être tenue responsable de leurs pratiques.{" "}
    </Row>
    <Row className="my-3">
      <h5 className="bold">
        3. Droits accordés aux Utilisateurs sur leurs données personnelles
      </h5>
      Toute personne physique utilisant le Site internet a la faculté d’exercer
      les droits suivants : <br />
      <br />- Un droit de rectification, <br />
      <br />- Un droit d’opposition,
      <br />
      <br /> - Un droit de suppression total ou partiel de son compte et de ses
      données personnelles,
      <br />
      <br />- Un droit d’accès aux données conservées par BOOK’NOW à son sujet.
      L’Utilisateur peut exercer l’ensemble de ces droits en contactant BOOK’NOW
      par email à contact@book-now.fr ou par courrier simple à l’adresse 60 rue
      François 1er 75008 Paris. Il doit indiquer ses coordonnées (nom, prénom,
      adresse postale ou email) et joindre une copie de sa pièce d’identité.
      BOOK’NOW apportera une réponse dans les plus brefs délais.
    </Row>
    <Row className="my-3">
      <h5 className="bold">
        4. Mesures mises en place afin d’assurer la sécurité des données
        personnelles
      </h5>
      En qualité de responsable de traitement, BOOK’NOW s’engage à prendre
      toutes les mesures utiles pour assurer la sécurité et la confidentialité
      des données des utilisateurs conformément au RGPD, notamment pour empêcher
      que ces données soient déformées, endommagées ou que des tiers non
      autorisés y aient accès. L’ensemble des données est hébergé en France ou
      dans l’Union Européenne.
    </Row>
    <Row className="my-3">
      <h5 className="bold">5. Durées de conservation des données</h5>
      Les données des Utilisateurs sont conservées pour la stricte finalité pour
      lesquelles elles ont été collectées, conformément au RGPD. Les données
      utilisées aux fins de prospection peuvent être conservées pendant une
      durée de 3 ans à compter de la fermeture du compte de l’utilisateur ou du
      dernier contact avec ce dernier. Les données de l'Utilisateur sont
      effacées lorsque les durées de conservations expirent. Néanmoins, les
      données pourront être archivées au-delà des durées prévues pour les
      besoins de la recherche, de la constatation et de la poursuite des
      infractions pénales dans le seul but de permettre, en tant que besoins, la
      mise à disposition de ces données à l'autorité judiciaire.{" "}
    </Row>
    <Row className="my-3">
      <h5 className="bold">6. Cookies</h5>
      Lors de la navigation de l’Utilisateur sur le Site Internet, des
      informations relatives à cette navigation peuvent être enregistrées au
      travers de fichiers appelés « cookies ». Ces cookies permettent de :{" "}
      <br />
      <br />- Sauvegarder des informations relatives au compte client lorsque
      l’Utilisateur est connecté,
      <br />
      <br /> - Établir des statistiques de fréquentation du Site internet afin
      d’améliorer la qualité des services, <br />
      <br />- Sauvegarder le panier des missions souhaitées. La lecture ou le
      dépôt de certains cookies peut nécessiter le consentement préalable de
      l’Utilisateur. Dans ce cas, l’Utilisateur après avoir été informé via les
      informations du bandeau dédié aux cookies et les informations de la
      présente Politique de Confidentialité, manifeste son consentement en
      poursuivant sa visite sur le site.
    </Row>
  </Container>
);
// TODO
const Confidentialité = ({ show, setShow }) => {
  return (
    <Modal show={show} onHide={() => setShow(false)} size="lg">
      <Modal.Header closeButton>
        <h3>Politique de confidentialité</h3>
      </Modal.Header>
      <Modal.Body>
        <PolitiqueContent />
      </Modal.Body>
    </Modal>
  );
};

export default Confidentialité;
